/*
*交付管理-组建项目团队
*qiufh@bocspace.cn
*/
import React from 'react'
import {inject, observer} from 'mobx-react'
import { Link } from 'gatsby'
import * as mobx from 'mobx'
import { Container, Row, Col} from 'react-bootstrap'
import { Pagination, Popover } from 'antd'
import moment from 'moment'
import Layout from '../components/layout'
import JournalStore from '../store/journal/JournalStore'

@observer
export default class News extends React.Component {
	constructor(props) {
        super(props)
		this.store = new JournalStore();
	}

	componentWillReceiveProps(nextProps, nextContext) {

	}



	componentDidMount() {
        this.store.getNewsMainPagesByUserId();
	}

	componentWillMount(){

	}

	removePreFixTitle=(title, prefix, fixLength)=>{
       return title.substring(title.indexOf(prefix)+fixLength)
	}

    jumpToDetail=(id)=>{
		window.location.href = "/newsdetail?id="+id
	}

	showSizeChange = async (current, pageSize) => {
		this.store.params.pageIndex = 1;
		this.store.params.pageSize = pageSize;
		this.store.getNewsMainPagesByUserId()
	}

	onPageChange = async (pageNumber) => {
		this.store.params.pageIndex = pageNumber;
		this.store.getNewsMainPagesByUserId()
	}

	showTotal = (total) => {
		return `共 ${total} 条`;
	}

	render() {

const {newsList, params, newsTotalCount} = this.store;
const {pageIndex, pageSize} = mobx.toJS(params);

		return (
			<Layout>
      <Container className="news-types" fluid >
        <h1 className="label-name">News</h1>
      </Container>
      <Container className="news-list"  fluid >
		  {
			 newsList.map((item,index)=>{

				return(
					<div onClick={()=>{this.jumpToDetail(item.id)}} key={'news-'+index} className="news-item">
							<div className="intro-pic">
							<img src={item.introductionImage} />
							</div>
							<div className="intro-info">
							<div className="news-title">
							<Popover content={item.title}>{this.removePreFixTitle(item.title, "-新闻稿-", 5)}</Popover>
							</div>
							<div className="news-intro">
								{this.removePreFixTitle(item.introductionText,"新闻内容", 4)}
							</div>
							</div>
							<div className="news-date">
							<div className="date-month">{moment(item.createTime).format('DD/MM')}</div>
							<div className="date-line"></div>
							<div className="date-year">{moment(item.createTime).format('YYYY')}</div>
							</div>
						</div>
				)
			 }) 
		  }
        

        <div className="news-page">
          <Pagination current={pageIndex} pageSize={pageSize} onShowSizeChange={this.showSizeChange} onChange={this.onPageChange} total={newsTotalCount} />
        </div>
      </Container>
    </Layout>
		)
	}
}



