/*
 * @Author: Tiger
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2021-01-18 16:57:42
 * @LastEditors: Please set LastEditors
 * @Description: About Us
 * @FilePath: /official_website/src/pages/about.js
 */ 
import React from 'react'
import {Provider} from 'mobx-react'

import News from '../components/News'

const NewsPage = () => {

  return (
    <Provider>
      <News />
    </Provider>
  )
}

export default NewsPage